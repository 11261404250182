
import { Vue, Component, Prop } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import store from "@/store";

@Component
export default class MenuList extends Vue {
  @Prop({ default: "" }) readonly group!: string;
  @Prop({ default: [] }) readonly items!: Array<RouteConfig>;
  @Prop({ default: false }) readonly mini!: boolean;

  selectedItem: any;

  constructor() {
    super();
    this.selectedItem = null;
  }

  get authenticatedUser() {
    return store.getters["auth/authenticatedUser"];
  }

  get selectedCompanyId() {
    return store.getters["auth/currentUserCompanyId"];
  }

  get filteredItems() {
    return this.items.filter((item) => {
      if (!item.meta!.subgroup) {
        const permissionConfigForThisUser = item.meta!.requiredPermissions.find(
          (permissionList: any) =>
            permissionList.userType === this.authenticatedUser.type
        );
        const requiredPermissions = permissionConfigForThisUser?.permissions;
        return (
          !!permissionConfigForThisUser &&
          this.hasPermissions(requiredPermissions)
        );
      } else if (item.meta!.subgroup) {
        return item.children && this.filteredChildren(item.children).length > 0;
      } else {
        return false;
      }
    });
  }

  filteredChildren(children: Array<RouteConfig>) {
    return children.filter((childItem) => {
      const permissionConfigForThisUser =
        childItem.meta!.requiredPermissions.find(
          (permissionList: any) =>
            permissionList.userType === this.authenticatedUser.type
        );
      const requiredPermissions = permissionConfigForThisUser?.permissions;
      return (
        !!permissionConfigForThisUser &&
        this.hasPermissions(requiredPermissions)
      );
    });
  }

  handleChildItemClick(item, childItem) {
    const targetRoute = {
      name: childItem.name,
      params: {}
    };

    // Substituir a rota atual pela nova rota
    this.$router.replace(targetRoute);

    this.$nextTick(() => {
      const childrenRoutes = item.children.map((child) => ({
        name: child.name,
        path: child.path
      }));
    });
  }

  handleMenuItemClick(item) {
    if (item.children && item.children.length > 0) {
      this.selectedItem = item;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ name: item.name }).catch(() => {});
    }
  }

  handleGroupItemClick(item) {
    if (item.children && item.children.length > 0) {
      this.selectedItem = item;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ name: item.name }).catch(() => {});
    }
  }
}


import { Vue, Component } from "vue-property-decorator";
import SelectCompany from "@/components/companies/SelectCompany.vue";
import MenuList from "@/components/core/dashboard/MenuList.vue";
import SafetyService from "@/services/safety-service";
import store from "@/store";
import MenuItem from "@/types/menu-item";

// import { adminMenuItems } from "@/menus/admin-menu-items";
// import { companyMenuItems } from "@/menus/company-menu-items";
// import { financialMenuItems } from "@/menus/financial-menu-items";
// import { borrowerMenuItems } from "@/menus/borrower-menu-items";
import router from "@/router";

import { RouteConfig } from "vue-router";
import Partner from "@/types/partner";
import PartnerService from "@/services/partner-service";
import Profile from "../../../types/profile";

@Component({
  components: { SelectCompany, MenuList }
})
export default class Header extends Vue {
  name: string;
  sidebarMenu: boolean;
  toggleMini: boolean;
  routesList: Array<RouteConfig>;
  safetyService: SafetyService;
  partnerService: PartnerService;
  partner: Partner | null;

  constructor() {
    super();
    this.safetyService = SafetyService.getInstance();
    this.partnerService = PartnerService.getInstance();
    this.name = "";
    this.sidebarMenu = this.$vuetify.breakpoint.smAndDown ? false : true;
    this.toggleMini = false;
    this.routesList = this.menuItens;
    this.partner = null;
  }

  mounted(): void {
    if (
      this.authenticatedUser &&
      this.authenticatedUser.type === "PARTNER_MASTER"
    ) {
      this.loadPartner();
    }
  }

  async loadPartner(): Promise<void> {
    const [partnerError, partner] =
      await this.partnerService.getLoggedPartner();
    if (partnerError) {
      this.$store.dispatch("snackbar/showError", partnerError);
    } else {
      this.partner = partner;
    }
  }

  get userType(): string {
    const user = this.authenticatedUser;
    if (user) {
      const type = this.safetyService.userTypes.find(
        (t) => t.value === user.type
      );
      if (type) {
        return type.text;
      }
    }
    return "";
  }

  get authenticatedUser() {
    return store.getters["auth/authenticatedUser"];
  }

  get mini() {
    return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
  }

  set mini(value) {
    this.toggleMini = value;
  }

  get scrollbarTheme() {
    return this.$vuetify.theme.dark ? "dark" : "light";
  }

  toggleTheme() {
    return (this.$vuetify.theme.dark = !this.$vuetify.theme.dark);
  }

  /*
    TODO: implement recursive version from depth > 2 and verify meta tags for authorization and authentication
  */
  get menuItens() {
    const menuItems: RouteConfig[] = [];

    this.$router.options.routes?.forEach((parent) => {
      if (parent.children?.length) {
        parent.children.forEach((children) => {
          if (children.meta!.showInMenu) {
            menuItems.push(children);
          }
        });
      } else {
        if (parent.meta?.showInMenu) {
          menuItems.push(parent);
        }
      }
    });
    return menuItems;
  }

  async handleLogout() {
    try {
      await this.$store.dispatch("auth/signOut");
      router.push({ name: "Auth" });
    } catch (err) {
      this.$notify({
        type: "error",
        text: "Erro ao realizar logout."
      });
    }
  }

  onCompanySelected(company: any): void {
    const companyId = company ? company.id : null;
    if (companyId) {
      const user = store.getters["auth/authenticatedUser"];
      if (user.type === "EMPLOYEE") {
        const profile: Profile = user.profiles.find(
          (profile: Profile) => profile.companyId === companyId
        );
        const permissions = profile.permissions.map(
          (permission) => permission.name
        );
        store.commit("auth/setUserPermissions", permissions);
      }
    }

    store.commit("auth/setCurrentUserCompanyId", companyId);
  }
}

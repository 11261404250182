
import { Vue, Component } from "vue-property-decorator";
import Header from "@/components/core/dashboard/Header.vue";
import Footer from "@/components/core/dashboard/Footer.vue";
import { EventBus } from "@/events/event-bus";

@Component({
  components: { Header, Footer },
})
export default class DashboardLayout extends Vue {
  requests: number;

  constructor() {
    super();
    this.requests = 0;
  }

  created() {
    EventBus.$on("requests", (payload: number) => {
      this.requests = payload;
    });
  }
}
